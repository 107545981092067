/* eslint-disable @next/next/no-img-element */
import { Box, Flex, Image, useMediaQuery } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { RefObject } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getPrivacyByPartner, getTncByPartner, isUK } from '../../utils/partnerTheme';

interface Props {
  navigate?: (ref: RefObject<HTMLDivElement>) => void;
  buyPlanRef?: RefObject<HTMLDivElement>;
  requestFixRef?: RefObject<HTMLDivElement>;
  faqRef?: RefObject<HTMLDivElement>;
}

const getLogoByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'PNFNG':
      return '/images/logo-white.svg';
    case 'MTNNG':
      return '/images/footer-partner-logo.svg';
    case 'AIRNG':
      return '/images/air-footer-partner-logo.svg';
    default:
      return '/images/logo-white.svg';
  }
};

const Footer = ({ navigate, buyPlanRef, faqRef, requestFixRef }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 479px)');
  const router = useRouter();
  const { show } = useIntercom();

  return (
    <Box pt={5} as="footer" className="footer">
      <Box className="container---main" maxW="1400px" mx="auto">
        <Box className="footer-contents-wrapper">
          <Flex flexWrap="wrap" gap={20}>
            <Flex
              width={isMobile ? '100%' : '25%'}
              justifyContent="space-between"
            >
              <Box>
                <Box
                  id="w-node-_185b503c-0393-bd40-ddd7-aaf7c03a6e2d-55b37658"
                  className="footer-menu"
                >
                  <Box
                    cursor="pointer"
                    onClick={() =>
                      navigate && requestFixRef
                        ? navigate(requestFixRef)
                        : router.push('/request-fix/category')
                    }
                    className="footer-link"
                  >
                    Request A Fix
                  </Box>
                  {!isUK && (
                    <Box
                      cursor="pointer"
                      onClick={() =>
                        navigate && buyPlanRef
                          ? navigate(buyPlanRef)
                          : router.push('/device-care/plan')
                      }
                      className="footer-link"
                    >
                      Protect My Device
                    </Box>
                  )}

                  <Box
                    cursor="pointer"
                    onClick={() =>
                      navigate && faqRef
                        ? navigate(faqRef)
                        : router.push('/faqs')
                    }
                    className="footer-link"
                  >
                    FAQs
                  </Box>
                  <Link href={getTncByPartner()} className="footer-link">
                    Terms of Service
                  </Link>
                </Box>
              </Box>
              {/*<Box>*/}
              {/*  <Box*/}
              {/*    id="w-node-_185b503c-0393-bd40-ddd7-aaf7c03a6e2d-55b37658"*/}
              {/*    className="footer-menu"*/}
              {/*  >*/}
              {/*    <Box*/}
              {/*      cursor="pointer"*/}
              {/*      onClick={() => ''}*/}
              {/*      className="footer-link"*/}
              {/*    >*/}
              {/*      Mobile Protection*/}
              {/*    </Box>*/}
              {/*    <Box*/}
              {/*      cursor="pointer"*/}
              {/*      onClick={() => router.push('#')}*/}
              {/*      className="footer-link"*/}
              {/*    >*/}
              {/*      iPhone Protection*/}
              {/*    </Box>*/}
              {/*    <Box*/}
              {/*      cursor="pointer"*/}
              {/*      onClick={() => router.push('#')}*/}
              {/*      className="footer-link"*/}
              {/*    >*/}
              {/*      Samsung Protection*/}
              {/*    </Box>*/}
              {/*    <Box*/}
              {/*      cursor="pointer"*/}
              {/*      onClick={() => router.push('#')}*/}
              {/*      className="footer-link"*/}
              {/*    >*/}
              {/*      Vivo Protection*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*</Box>*/}
              <Box>
                <Box
                  id="w-node-_185b503c-0393-bd40-ddd7-aaf7c03a6e2d-55b37658"
                  className="footer-menu"
                >
                  {/*<Box*/}
                  {/*  cursor="pointer"*/}
                  {/*  onClick={() => router.push('/about')}*/}
                  {/*  className="footer-link"*/}
                  {/*>*/}
                  {/*  About Picknfix*/}
                  {/*</Box>*/}
                  {/*<Box*/}
                  {/*  cursor="pointer"*/}
                  {/*  onClick={() => router.push('/contact')}*/}
                  {/*  className="footer-link"*/}
                  {/*>*/}
                  {/*  Contact Us*/}
                  {/*</Box>*/}
                  <Box cursor="pointer" onClick={show} className="footer-link">
                    Help Center
                  </Box>
                  <Link href={getPrivacyByPartner()} className="footer-link">
                    Privacy Policy
                  </Link>
                </Box>
              </Box>
            </Flex>
          </Flex>

          <Box className="footer-bottom-contents">
            <Box className="horizontal-line white"></Box>
            <Box className="footer-cta">
              <Link
                href="/"
                aria-current="page"
                className="footer-logo-link w-inline-block w--current"
              >
                <Image
                  src={getLogoByPartner()}
                  alt="Footer Logo"
                  width={[180, 180]}
                  className="footer-logo-image"
                />
              </Link>
            </Box>
            <Box className="footer-copyright-social">
              <Box className="small-text footer-copyright-text">
                © Picknfix Limited 2024
              </Box>
              <Box className="social-links">
                <Link
                  href="https://www.facebook.com/mypicknfix/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/images/facebook-logo.svg" alt="Facebook" />
                </Link>

                <Link
                  href="https://x.com/mypicknfix_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/images/twitter-logo.svg" alt="Twitter" />
                </Link>

                <Link
                  href="https://www.instagram.com/mypicknfix?igsh=MWx2cTQ1MHEyN2Ew"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/images/insta-logo.svg" alt="Instagram" />
                </Link>

                <Link
                  href="https://www.linkedin.com/company/picknfix/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/images/linkedin-logo.svg" alt="Linkedin" />
                </Link>
                {/* <Link
                  href="https://www.instagram.com/yourpage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/images/youtube-logo.svg" alt="Youtube" />
                </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
